import React, { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ColumnChart from 'components/columnChart/columnChart';
import { MobxContext } from 'mobx/mobxContext';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import RegularTooltip from 'components/tooltips/regularTooltip';

export default observer(function SubDepartmentChart() {
  const {
    mobxStore: { subDepartmentStore, employeeStore },
  } = useContext(MobxContext);

  const { adaptedSubDepartmentData } = subDepartmentStore;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    subDepartmentStore.getReviewPeriodIds();
  }, [subDepartmentStore]);

  const formatterFn: Highcharts.TooltipFormatterCallbackFunction = function () {
    const datePeriod = this.series.name;

    return ReactDOMServer.renderToString(<RegularTooltip date={datePeriod} />);
  };

  const handleSubDepartmentPath = function (
    e: Highcharts.SeriesClickEventObject
  ) {
    if (location.pathname.includes('/sub_department/')) {
      const options = e.point.options as any;
      const employeeId = options.employee_id;
      const employeeName = options.employee_name;
      employeeStore.fetchEmployeeData(employeeId, employeeName);
      navigate(`/employee/${employeeStore.currentEmployeeId}`);
    }
  };

  const isScrollEnabled = useMemo(() => {
    try {
      // костыль
      // @ts-ignore
      return adaptedSubDepartmentData?.series?.[0]?.data?.length > 6;
    } catch (err) {
      return false;
    }
  }, [adaptedSubDepartmentData]);

  if (!adaptedSubDepartmentData?.series) {
    return null;
  }

  return (
    <Box>
      <ColumnChart
        chartData={{
          ...adaptedSubDepartmentData,
          axisParams: {
            ...adaptedSubDepartmentData?.axisParams,
            isScrollEnabled: isScrollEnabled,
          },
        }}
        plotOptionsArgs={{
          handleClick: handleSubDepartmentPath,
          cursor: 'pointer',
        }}
        tooltipOptionsArgs={{
          formatter: formatterFn,
          outside: false,
          padding: 8,
        }}
      />
    </Box>
  );
});
